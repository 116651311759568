import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "morph-wrapper" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Header = _resolveComponent("Header");
    const _component_morph_figure = _resolveComponent("morph-figure");
    const _component_pagination = _resolveComponent("pagination");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_Header),
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_morph_figure, { "current-state": _ctx.currentPage }, null, 8, ["current-state"])
        ]),
        _createVNode(_component_pagination, {
            currentPage: _ctx.currentPage,
            "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event) => ((_ctx.currentPage) = $event)),
            "number-of-pages": _ctx.numberOfPages,
            class: "section-pagination"
        }, null, 8, ["currentPage", "number-of-pages"])
    ], 64));
}
