import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2228cf63"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "header" };
const _hoisted_2 = { class: "logo-wrapper" };
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_navigation = _resolveComponent("navigation");
    return (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
                src: _ctx.Logo,
                alt: "Logo"
            }, null, 8, _hoisted_3)
        ]),
        _withDirectives(_createVNode(_component_navigation, null, null, 512), [
            [_vShow, false]
        ])
    ]));
}
