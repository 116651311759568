import { defineComponent } from "vue";
export default defineComponent({
    name: "MorphFigure",
    props: {
        currentState: {
            type: Number,
            default: 1,
            required: true,
        },
    },
});
