import { defineComponent } from "vue";
export default defineComponent({
    name: "Navigation",
    methods: {
        addZero(value) {
            return `0${value}`;
        },
        onClick(ind) {
            this.$emit("update:currentPage", ind + 1);
        },
    },
    computed: {},
});
