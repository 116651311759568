import { defineComponent } from "vue";
import Header from "@/components/Header/index.vue";
import Pagination from "@/components/Pagination.vue";
import MorphFigure from "@/components/MorphFigure.vue";
export default defineComponent({
    name: "Studio",
    components: {
        Pagination,
        Header,
        MorphFigure,
    },
    data: () => {
        return {
            currentPage: 1,
            numberOfPages: 5,
        };
    },
    mounted() {
        document.addEventListener("keyup", this.keyListener);
    },
    beforeUnmount() {
        window.removeEventListener("keyup", this.keyListener);
    },
    methods: {
        keyListener(event) {
            if (this.currentPage > 1 && event.key === "ArrowUp") {
                this.arrowUpHandler();
            }
            if (this.currentPage < 5 && event.key === "ArrowDown") {
                this.arrowDownHandler();
            }
        },
        arrowUpHandler() {
            this.currentPage--;
        },
        arrowDownHandler() {
            this.currentPage++;
        },
    },
});
