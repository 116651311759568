import { defineComponent } from "vue";
export default defineComponent({
    name: "Pagination",
    props: {
        currentPage: {
            type: Number,
            default: 1,
            required: true,
        },
        numberOfPages: {
            type: Number,
            default: 5,
        },
    },
    methods: {
        addZero(value) {
            return `0${value}`;
        },
        onClick(ind) {
            this.$emit("update:currentPage", ind + 1);
        },
    },
    computed: {
        currentPageIndex() {
            const { currentPage } = this;
            return currentPage - 1;
        },
    },
});
