import { defineComponent } from "vue";
import Logo from "./media/images/logo2.svg";
import Navigation from "@/components/Navigation.vue";
export default defineComponent({
    name: "Header",
    components: {
        Navigation,
    },
    data: () => {
        return {
            Logo,
        };
    },
});
